// App Styles
// ----------------------------------------------------------------------------
// Put style rules here that you want to apply to the entire application. These 
// styles are for the entire app and not just one component. Additionally, this 
// file can hold Sass mixins, functions, and placeholder classes to be imported 
// and used throughout the application.

* {
  outline: none;
}

.require-rotate {
  display: none;
  position: fixed;
  z-index: 999999;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #000 url('/assets/backgrounds/turn-device.png') center center / cover no-repeat;
}

@media (max-height: 599px) and (orientation: landscape) {
  .require-rotate {
    display: block;
  }
  .require-rotate.video {
    display: none;
  }
}

body {
  --themeWhite: #fff;
  --themeColor1: #744e9a;
  --themeColor2: #f6c12a;
  --themeColor3: #f7a37f;
  --themeColor4: #f2dcbc;
  --themeColor5: #4abcc1;
}

.theme-custom,
.theme-custom-nologo {
  --themeWhite: #fff;
  --themeWhiteRgb: 255,255,255;

  @media (min-width: 768px) and (min-height: 480px) {
    --themeWhite: #000;
    --themeWhiteRgb: 0,0,0;
  }
}

ion-content {
  --background: transparent;
}

.top-logo {
  position: absolute;
  top: 20px;
  right: 25px;
  width: 35px;
  height: 35px;
  background: transparent url('/assets/logos/corner.png') center center / cover no-repeat;
  z-index: 999;

  @media (min-width: 768px) and (min-height: 768px) {
    display: none;
  }
}

.theme-custom {
  .top-logo {
    background: transparent url('/assets/logos/corner.png') center center / cover no-repeat;
  }
}


.back-button {
  position: absolute;
  top: 5px;
  left: 5px;
}

.back-button.light {
  color: var(--themeWhite);
}

.title,
.subtitle {
  text-align: center;
  width: 90%;
  font-size: 15px;
  margin: 10px auto;
}

.title {
  font-weight: bold;
  font-size: 20px;
  margin-top: 50px;

  &.close {
    margin-bottom: 3px;
  }

  &.absolute {
    position: absolute;
    top: 50px;
    left: 50%;
    width: 60%;
    margin-top: 0px;
    transform: translateX(-50%);
    text-align: center;
  }
}

.subtitle {
  margin-bottom: 35px;

  &.close {
    margin-top: 3px;
  }
  
  &.absolute {
    position: absolute;
    top: 85px;
    left: 50%;
    width: 80%;
    transform: translateX(-50%);
    text-align: center;
  }
}

.input {
  border: solid 1px #aaa;
  border-radius: 10px;
  --padding-end: 10px;
  --padding-start: 10px;
  margin-bottom: 15px;
}

.error {
  text-align: center;
  color: rgb(228, 83, 83);
}

.success {
  text-align: center;
  color: #5fb78d;
}

.submit-round {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--themeWhite);
  font-size: 85px;
  cursor: pointer;
  z-index: 999;
}

.submit {
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 30px;
  color: #fff;
  font-weight: bold;
  --color-activated: #fff;
  --background: rgb(1,133,139);
  --background: linear-gradient(90deg, rgba(1,133,139,1) 0%, rgba(4,94,98,1) 100%);
  --background-hover: rgb(1,133,139);
}

.form {
  position: absolute;
  top: 45%;
  left: 30px;
  right: 30px;
  transform: translateY(-50%);
}

.form-top {
  margin: 30px 30px 0 30px;
}

.theme-default {
  .background {
    .image {
      background-image: url('/assets/backgrounds/shutterstock_486846196.jpg');
      filter: blur(4px);
    }
  }
}

.centered {
  display: flex;

  @media (min-width: 768px) and (min-height: 480px) {
    position: absolute;
    top: 70px;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
    height: auto;
    min-width: 350px;
  }

  @media (min-width: 768px) and (min-height: 768px) {
    top: 140px;
    bottom: 70px;
  }
  
  .desktop-tabs {
    display: none;
    width: 350px;
    margin-left: 40px;

    @media (min-width: 992px) and (min-height: 800px) {
      display: block;
    }

    &.hide {
      display: none;
    }

    .buttons {
      background-color: #fff;
      padding: 10px 20px;

      ion-row {
        color: #000;
        align-items: center;
        cursor: pointer;

        &.disabled {
          color: #aaa;
        }

        ion-icon {
          font-size: 40px;
          margin-right: 20px;
        }

        &.active {
          color: var(--themeColor1);
        }
      }
    }
  }

  @media (min-width: 992px) and (min-height: 800px) {
    .app-router {
      position: relative;
      min-width: 350px;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
    }
  }
}

.theme-video {
  @media (min-width: 768px) and (min-height: 480px) {
    .centered {
      position: absolute;
      top: 50%;
      left: 50%;
      bottom: auto;
      right: auto;
      transform: translate(-50%, -50%);
      height: 336px;
      width: 600px;
    }
  }

  @media (min-width: 992px) and (min-height: 800px) {
    .centered {
      height: 416px;
      width: 740px;
    }

    .app-router {
      position: static;
      width: 600px;
    }
  }
}

ion-tab-bar {
  @media (min-width: 992px) and (min-height: 800px) {
    display: none;
  }
}

.page {
  @media (min-width: 768px) and (min-height: 480px) {
    background: #fff;
  }
}

.theme-clean {
  .top-logo {
    background: transparent url('/assets/logos/corner.png') center center / cover no-repeat;
  }

  .page {
    background: #fff;
  }
}

.theme-top,
.theme-top-nologo {
  .page {
    background: #fff;
  }

  .top-logo {
    background: transparent url('/assets/logos/full.png') center center / cover no-repeat;
    width: 164px;
    height: 50px;
    right: 50%;
    transform: translateX(50%);
    top: 80px;
  }

  .background {
    background: #fff;
  }
}

.theme-video,
.theme-video-lock {
  .page {
    background: #000;
  }

  .top-logo {
    display: none;
  }

  .background {
    background: #000;
  }
}

.theme-nologo,
.theme-custom-nologo,
.theme-top-nologo {
  .top-logo {
    display: none;
  }
}

.theme-bottom {
  .top-logo {
    background: transparent url('/assets/logos/full.png') center center / cover no-repeat;
  }

  .page {
    background: #fff;
  }

  .background {
    background: #fff;
  }
}

.text-center {
  text-align: center;
}

.loading {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #fff;
  z-index: 99999;

  .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
    width: 130px;
    height: 42px;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.modal-backdrop {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 999;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    position: relative;
    border-radius: 10px;
    background-color: #fff;
    border: solid 2px var(--themeColor1);
    padding: 20px;
    width: 90%;
    overflow: auto;
    max-height: 80%;

    &.button {
      padding-bottom: 77px !important;
    }

    .close {
      position: absolute;
      top: 0px;
      right: 0px;
      font-size: 15px;
      --color: var(--themeColor1);
    }

    h3 {
      font-size: 16px;
      font-weight: bold;
    }

    p {
      font-size: 14px;
    }

    .footer-button {
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
      border-top: solid 1px var(--themeColor1);
      height: 55px;
      color: #000;
    }
  }
}

.uppercase {
  text-transform: uppercase;
}

.header {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 100px;
  display: none;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1px #eee;

  @media (min-width: 768px) and (min-height: 768px) {
    display: flex;
  }

  .header-logo {
    width: 160px;
    display: block;
    height: auto;
  }
}

.fill-white {
  fill: #fff;

  @media (min-width: 768px) and (min-height: 480px) {
    fill: var(--themeColor1);
    opacity: 1;
  }
}

.fill-grey {
  fill: #ccc;

  @media (min-width: 768px) and (min-height: 480px) {
    fill: var(--themeColor1);
    opacity: 0.3;
  }
}

.fill-color1 {
  fill: var(--themeColor1);
}

.fill-color2 {
  fill: var(--themeColor2);
}

.theme-video .desktop-tabs {
  display: none;
}

.content a {
    color: var(--themeWhite) !important;
    text-decoration: underline;
}